<ng-container *ngIf="state.mainContent | async as main">
    <article class="conference">
        <div class="wrapper">
            <h1 class="conference-date" [innerHTML]="main.conferenceDate" appRevealText></h1>
            <h3 class="location" appRevealText>
                <span [innerHTML]="main.locationFirstLine"></span>
                <br>
                <span [innerHTML]="main.locationSecondLine"></span>
            </h3>
    
            <p class="conference-description" [innerHTML]="main.firstAbout">
            </p>
            <p class="conference-description" [innerHtml]="main.secondAbout">
            </p>
        </div>
    </article>
    
    <div class="sessions-header">
        <div class="wrapper">
            <h1 [innerHTML]="main.liveSessions" appRevealText></h1>
            <h3 [innerHTML]="main.timeInfo" appRevealText>
            </h3>
        </div>
    </div>
    
    <ul class="sessions">
        <app-article-presentation *ngFor="let event of state.events | async" [event]="event">
    
        </app-article-presentation>
    </ul>
</ng-container>