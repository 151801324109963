import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable()
export class ContentService {
  private readonly baseUrl = `https://worldviews-cms.herokuapp.com/`;
  constructor(private http: HttpClient, private platform: PlatformService) {}

  find<T>(content: string, params?: any): Observable<T | []> {
    if (!this.platform.isBrowser) {
      return of([]);
    }

    let queryParams: HttpParams = new HttpParams();

    if (!!params) {
      Object.keys(params).forEach((key) => {
        const value = `${params[key]}`;
        queryParams = queryParams.append(key, value);
      });
    }

    if (this.platform.isBrowser) {
      return this.http.get<T>(`${this.baseUrl}${content}`, {
        params: queryParams,
      });
    }

    return of([]);
  }
}
