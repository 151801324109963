import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { getLang } from './lang.config';

@Pipe({
  name: 'ordinalDate',
})
export class OrdinalDatePipe implements PipeTransform {
  private months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(private date: DatePipe) {}

  /* Date in format: YYYY-MM-DD */
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const day = +value.substr(8, 2);
    const monthAsNumber = +value.substr(5, 2);
    const ordinal = this.nth(monthAsNumber);
    const monthAsString = this.date.transform(value, 'MMM', 'UTC');

    return `${monthAsString} ${day}${ordinal}`;
  }

  private nth(d: number): string {
    if (d > 3 && d < 21) {
      return 'th';
    }

    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
