import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { getLang, setLang } from '../lang.config';
import { PlatformService } from '../services/platform.service';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit {
  constructor(
    public state: StateService,
    private platform: PlatformService,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // this.setMenuItemsHeight();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setMenuItemsHeight();
  }

  toggleMenu(): void {
    this.state.toggleMenu();
    setTimeout(() => this.setMenuItemsHeight());
  }

  closeMenu(): void {
    this.state.closeMenu();
  }

  isActive(lang: string): boolean {
    if (!this.platform.isBrowser) {
      return false;
    }

    return getLang() === (lang as string);
  }

  setLang(lang: string): void {
    if (!this.platform.isBrowser) {
      return;
    }

    const storedLang = getLang();
    if ((lang as string) === storedLang) {
      return;
    }

    setLang(lang);
    window.location.reload();
  }

  private setMenuItemsHeight(): void {
    const menuItemsHeight =
      this.elementRef.nativeElement.querySelector('.menu-items')?.clientHeight;
    if (menuItemsHeight) {
      document.body.style.setProperty(
        '--menu-items-height',
        menuItemsHeight + 'px'
      );
    }
  }
}
