import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LineSplitter } from '../animations/line-splitter';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Subscription } from 'rxjs';
import { PlatformService } from '../services/platform.service';
import { ViewportStateService } from '../services/viewport-state.service';

@Directive({
  selector: '[appRevealText]',
})
export class RevealTextDirective implements OnInit, AfterViewInit, OnDestroy {
  private scrollTrigger: any | null = null;
  private subscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private platform: PlatformService,
    private viewportState: ViewportStateService
  ) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add('reveal');
    gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterViewInit(): void {
    LineSplitter.split(this.elementRef.nativeElement);
    this.scrollTrigger = ScrollTrigger.create({
      start: 'top bottom',
      end: 'bottom top',
      toggleClass: 'visible',
      trigger: this.elementRef.nativeElement,
      // markers: true,
    });
    const viewportStateStream = this.viewportState
      .viewportHeightChanged()
      .subscribe(() => {
        if (this.scrollTrigger.isActive) {
          return;
        }

        this.scrollTrigger.refresh();
      });
    this.subscription.add(viewportStateStream);
  }

  ngOnDestroy(): void {
    if (this.scrollTrigger) {
      this.scrollTrigger?.kill();
      this.scrollTrigger = null;
    }

    this.subscription.unsubscribe();
  }
}
