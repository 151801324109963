import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PlatformService {
  private browserChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.browserChanged.next(isPlatformBrowser(this.platformId));
  }

  // get onIsBrowserChanged(): Observable<boolean> {
  //   return this.browserChanged.pipe(filter((isBrowser: boolean) => isBrowser));
  // }

  get isBrowser(): boolean {
    return this.browserChanged.value;
  }
}
