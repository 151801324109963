import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IEventDetails } from '../article-presentation/article-presentation.component';
import { PlatformService } from '../services/platform.service';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  event: IEventDetails | null = null;

  private subscription: Subscription = new Subscription();
  private mutationObserver: MutationObserver | null = null;
  constructor(
    public state: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
    private platform: PlatformService
  ) {}

  ngOnInit(): void {
    this.state.isEventPage = true;
    const eventsStream = this.route.paramMap
      .pipe(
        map((paramMap: ParamMap) => {
          const eventId = paramMap.get('id');
          if (!eventId) {
            this.router.navigate(['/home']);
            return null;
          }

          return +eventId;
        })
      )
      .pipe(filter((eventId: number | null) => !!eventId))
      .pipe(
        mergeMap((eventId: any) =>
          this.state.events.pipe(
            map((events: IEventDetails[]) =>
              events.find((e) => e.id === eventId)
            )
          )
        )
      )
      .subscribe((event: IEventDetails | undefined) =>
        this.onEventRetrieved(event)
      );

    this.watchDomChanges();
    this.subscription.add(eventsStream);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    if (!!this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
    this.state.isEventPage = false;
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (!this.platform.isBrowser) {
      return;
    }

    this.updateIframesSizes(true);
  }

  private watchDomChanges(): void {
    const MutationObserver = window.MutationObserver;

    if (MutationObserver) {
      this.mutationObserver = new MutationObserver(() => this.updateIframesSizes());

      // have the observer observe foo for changes in children
      this.mutationObserver.observe(document, { childList: true, subtree: true });
    }
  }

  private updateIframesSizes(refresh = false): void {
    const componentElement = this.elementRef.nativeElement;

    if (!componentElement){
      return;
    }

    const queryClass = refresh ? 'iframe' : 'iframe' + ':not(.sized)';
    const iframes = Array.from(componentElement.querySelectorAll<HTMLIFrameElement>(queryClass));

    for (const iframe of iframes) {
      if(window.innerWidth <= 600) {
        iframe.height = (window.innerHeight / 4).toFixed(0) + 'px';
      } else {
        iframe.height = (window.innerHeight / 3).toFixed(0) + 'px';
      }

      iframe.width = '100%';
      iframe.classList.add('sized');
    }
  }

  private onEventRetrieved(event: IEventDetails | undefined): void {
    if (!event) {
      this.router.navigate(['/home']);
      return;
    }

    event.video = this.getFrame(event.video);

    event.chair = event.chair.map((s) => ({
      ...s,
      video: this.getFrame(s.video),
    }));
    event.speakers = event.speakers.map((s) => ({
      ...s,
      video: this.getFrame(s.video),
    }));

    if (event.keynote) {
      event.keynote = event.keynote.map((s) => ({
        ...s,
        video: this.getFrame(s.video),
      }));
    }
    this.event = event;
  }

  private getFrame(video?: string): any {
    if (!video) {
      return null;
    }
    return this.domSanitizer.bypassSecurityTrustHtml(video);
  }
}
