import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IEventDetails } from './article-presentation/article-presentation.component';
import { ContentService } from './services/content.service';
import { debounceTime, filter, map, throttleTime } from 'rxjs/operators';
import { PlatformService } from './services/platform.service';
import { getLang, setLang } from './lang.config';
import { RevealTextAnimation, splitLines } from './animations/split-lines';
import 'gsap';
import { StateService } from './services/state.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Route,
  Router,
  Scroll,
} from '@angular/router';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ViewportStateService } from './services/viewport-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  events: IEventDetails[] = [];
  scrollTop = 0;
  menuBottom: number | undefined = undefined;
  scrolled = false;
  overflow = false;

  private subscription: Subscription = new Subscription();
  private scrollStream: Subject<void> = new Subject<void>();

  @ViewChild('mobileMenu') mobileMenu: ElementRef<HTMLElement> | undefined;
  @ViewChild('sponsors') sponsors: ElementRef<HTMLElement> | undefined;
  constructor(
    public state: StateService,
    private content: ContentService,
    private platform: PlatformService,
    private router: Router,
    private route: ActivatedRoute,
    private viewportState: ViewportStateService
  ) {
    this.fetchContent();
  }

  ngOnInit(): void {
    const scrollSub = this.scrollStream
      .pipe(throttleTime(10))
      .subscribe(() => this.updateMenu());
    this.subscription.add(scrollSub);
  }

  ngAfterViewInit(): void {
    this.viewportState.startCheck();
    const pageChangeStream = this.state.onEventPageChanged().subscribe(() => {
      setTimeout(() => this.updateMenu());
      this.updateCanvas();
    });

    this.subscription.add(pageChangeStream);
  }

  ngOnDestroy(): void {
    this.viewportState.stopCheck();
    this.subscription.unsubscribe();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (!this.platform.isBrowser) {
      return;
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    this.setCanvasDimensions(windowWidth, windowHeight);
    this.updateCanvas();
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (!this.platform.isBrowser) {
      return;
    }

    // this.scrollStream.next();
    this.updateMenu();
  }

  isActive(lang: string): boolean {
    if (!this.platform.isBrowser) {
      return false;
    }

    return getLang() === (lang as string);
  }

  setLang(lang: string): void {
    if (!this.platform.isBrowser) {
      return;
    }

    const storedLang = getLang();
    if ((lang as string) === storedLang) {
      return;
    }

    setLang(lang);
    window.location.reload();
  }

  toggleMenu(): void {
    this.state.toggleMenu();
  }

  getFooter(): string {
    const lang = getLang();
    if (lang === 'en') {
      return 'Click here';
    }

    if(lang === 'es') {
      return 'Clique aqui';
    }

    if (lang === 'pt') {
      return 'Click aquí';
    }


    return 'click here';
  }

  private updateCanvas(): void {
    if (window.innerWidth >= 1199) {
      const canvas = document.querySelector<HTMLElement>('canvas');
      if (canvas) {
        canvas.style.visibility = 'visible';
      }
      return;
    }

    const canvas = document.querySelector<HTMLElement>('canvas');
    if (canvas) {
      if (this.state.isEventPage) {
        canvas.style.visibility = 'collapse';
      } else {
        canvas.style.visibility = 'visible';
      }
    } else {
      setTimeout(() => this.updateCanvas(), 200);
    }
  }

  private updateMenu(): void {

    if (window.innerWidth > 1199) {
      this.menuBottom = undefined;
      return;
    }

    if (this.state.isEventPage) {
      // this.scrollTop = 10000;
      this.overflow = true;

      setTimeout(() => {
        if (this.mobileMenu) {
          this.menuBottom = this.mobileMenu.nativeElement.clientHeight;
        }
      }, 200);
      // setTimeout(() => this.updateMenu());
      return;
    }

    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.scrolled = this.scrollTop > 10;
    // this.overflow = this.scrollTop > window.innerHeight * 0.8;

    if (this.mobileMenu && this.sponsors) {
      this.overflow =
        window.innerHeight -
          this.scrollTop -
          this.sponsors.nativeElement.clientHeight <=
        this.mobileMenu.nativeElement.clientHeight;
      this.menuBottom = this.mobileMenu.nativeElement.clientHeight;
    }
  }

  private fetchContent(): void {
    if (!this.platform.isBrowser) {
      return;
    }

    forkJoin([
      this.content.find<any>(`main-contents?lang=${getLang()}`),
      this.content
        .find<any>(`events?lang=${getLang()}&_sort=date:ASC,startTime:ASC`)
        .pipe(
          map((items: any) => {
            return items.map((item: any) => ({
              ...item,
              startTime: this.timeToDate(item.startTime),
              endTime: this.timeToDate(item.endTime),
              imagePath: item.image.url,
            }));
          })
        ),
    ])
      .pipe(map((value: [any, any]) => ({ main: value[0], events: value[1] })))
      .subscribe((value) => {
        this.state.setMainContent(value.main[0]);
        this.formatEvents(value.events);
      });
  }

  private formatEvents(items: any): void {
    const formatedEvents: IEventDetails[] = [];
    items.forEach((item: any) => {
      if (formatedEvents.some((e) => e.date === item.date)) {
        const foundItem = formatedEvents.find((e) => e.date === item.date);
        if (!!foundItem) {
          foundItem.noMargin = true;
        }
        formatedEvents.push({ ...item, hideDate: true});
        return;
      }

      formatedEvents.push(item);
    });

    this.state.setEvents(formatedEvents);
  }

  private timeToDate(time: string): Date {
    const date = new Date();
    const utc = new Date(date.getUTCDate());

    const timeAsArray = time.split(':');
    utc.setUTCHours(+timeAsArray[0], +timeAsArray[1]);

    return utc;
  }

  private setCanvasDimensions(windowWidth: number, windowHeight: number): void {
    const canvas = document.querySelector<HTMLElement>('canvas');
    if (!canvas) {
      return;
    }

    if (windowWidth >= 1200) {
      canvas.style.width = `${windowWidth / 2}px`;
      canvas.style.height = `${windowHeight}px`;
      canvas.style.position = 'fixed';
    } else {
      canvas.style.width = `${windowWidth}px`;
      canvas.style.height = `${windowHeight}px`;
      canvas.style.position = 'absolute';
    }
  }
}
