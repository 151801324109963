<li *ngIf="!!event" class="event">
    <div class="image">
        <img [src]="event.imagePath" [alt]="event.imageDescription">
        <h4 [innerHTML]="event.imageDescription"></h4>
    </div>

    <div class="participants">
        <h4 class="chair-header" appRevealText>Chair</h4>
        <ul class="chair">
            <li *ngFor="let chair of event.chair" class="participant" [innerHTML]="chair.name" appRevealText>

            </li>
        </ul>

        <h4 class="keynote-header" *ngIf="!!event.keynote && event.keynote.length > 0" appRevealText>Keynote</h4>
        <ul class="keynote" *ngIf="!!event.keynote">
            <li *ngFor="let keynote of event.keynote" class="participant" [innerHTML]="keynote.name" appRevealText>
            </li>
        </ul>

        <h4 class="speakers-header" appRevealText>Speakers</h4>
        <ul class="speakers">
            <li *ngFor="let speaker of event.speakers" class="participant" [innerHTML]="speaker.name" appRevealText>
            </li>
        </ul>
    </div>
    <div class="date" *ngIf="!!event.date && !event.hideDate" appRevealText>{{event.date | ordinalDate}}</div>

    <div class="time" appRevealText><span>{{event.startTime | date: 'h:mm': 'UTC'}}
        </span> - <span>{{event.endTime | date:'h:mm a':'UTC'}}</span></div>

    <h2 (click)="navigateToEventDetail()" class="event-name" appRevealText [innerHTML]="event.name"></h2>
    <p class="short-desc" [innerHTML]="event.shortDescription"></p>

    <a class="view-more" [routerLink]="'/' + event.id" appRevealText>{{getSessionButton()}} →</a>
</li>