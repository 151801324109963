<ng-container *ngIf="event">
    <div class="image" [class.fullscreen]="state.menuExpanded">
        <img [src]="event.imagePath" [alt]="event.imageDescription">
        <h4 [innerHTML]="event.imageDescription"></h4>
    </div>

    <h2 class="event-name" appRevealText [innerHTML]="event.name"></h2>
    <div class="date-time">
        <div class="date" appRevealText *ngIf="!!event.date">{{event.date | ordinalDate}}</div>
        <div class="time" appRevealText><span>{{event.startTime | date: 'h:mm':'UTC'}}</span> - <span>{{event.endTime | date:
                'h:mm a': 'UTC'}}</span></div>
    </div>

    <div class="video conference" *ngIf="event.video" [innerHTML]="event.video"></div>
    
    <p class="short-desc " [innerHTML]="event.longDescription"></p>
    <ng-container *ngFor="let speaker of event.chair">
        <div class="name" appRevealText>{{speaker.name}}</div>
        <div class="role" appRevealText>Chair</div>
        <div class="video" *ngIf="!!speaker.video" [innerHTML]="speaker.video"></div>
        <div class="about" [innerHTML]="speaker.about"></div>
    </ng-container>

    <ng-container class="speaker" *ngFor="let speaker of event.keynote">
        <div class="name" appRevealText>{{speaker.name}}</div>
        <div class="role" appRevealText>Keynote</div>
        <div class="video" *ngIf="speaker.video" [innerHTML]="speaker.video"></div>
        <div class="about" [innerHTML]="speaker.about"></div>
    </ng-container>

    <ng-container class="speaker" *ngFor="let speaker of event.speakers">
        <div class="name" appRevealText>{{speaker.name}}</div>
        <div class="role" appRevealText>Speaker</div>
        <div class="video" *ngIf="speaker.video" [innerHTML]="speaker.video"></div>
        <div class="about" [innerHTML]="speaker.about"></div>
    </ng-container>

</ng-container>