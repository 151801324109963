import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticlePresentationComponent } from './article-presentation/article-presentation.component';
import { ContentService } from './services/content.service';
import { PlatformService } from './services/platform.service';
import { OrdinalDatePipe } from './ordinal-date.pipe';
import { DatePipe } from '@angular/common';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { HomeComponent } from './home/home.component';
import { StateService } from './services/state.service';
import { RevealTextDirective } from './reveal-text/reveal-text.directive';
import { ViewportStateService } from './services/viewport-state.service';
import { MenuComponent } from './menu/menu.component';

const supportedLanguages = ['en', 'es', 'pt'];

function initializeApp(platformService: PlatformService): any {
  return () => {
    if (!platformService.isBrowser) {
      return;
    }

    const langKey = 'worldviews-lang';
    const storedLang = localStorage.getItem(langKey);

    if (!storedLang) {
      localStorage.setItem(langKey, 'en');
      return;
    }

    const isSupported = supportedLanguages.some((l) => l === storedLang);
    if (!isSupported) {
      localStorage.setItem(langKey, 'en');
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ArticlePresentationComponent,
    OrdinalDatePipe,
    EventDetailComponent,
    HomeComponent,
    RevealTextDirective,
    MenuComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    ContentService,
    PlatformService,
    OrdinalDatePipe,
    DatePipe,
    ViewportStateService,
    StateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [PlatformService],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
