import SplitType from 'split-type';

export class LineSplitter {
  static split(element: HTMLElement): void {
    const splitLine = new SplitType(element, { types: 'lines' });
    this.wrapLines(splitLine.lines, 'div', 'oh');
    element.classList.add('splited');
  }

  private static wrapLines(elems: any, wrapType: any, wrapClass: any): any {
    elems.forEach((char: any) => {
      // add a wrap for every char (overflow hidden)
      const wrapEl = document.createElement(wrapType);
      wrapEl.classList = wrapClass;
      char.parentNode.appendChild(wrapEl);
      wrapEl.appendChild(char);
    });
  }
}
