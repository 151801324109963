<ng-container *ngIf="state.mainContent | async as mainContent">

    <app-menu></app-menu>

    <!-- main -->
    <header>
        <ul class="lang" #lang (click)="lang.classList.toggle('expand')">
            <li [class.active]="isActive('en')" (click)="setLang('en')">EN</li>
            <li [class.active]="isActive('es')" (click)="setLang('es')">ES</li>
            <li [class.active]="isActive('pt')" (click)="setLang('pt')">PT</li>
        </ul>
        <h1 class="title" [innerHTML]="mainContent.title" routerLink="/" (click)="state.closeMenu()">
        </h1>
        <h3 class="subtitle" routerLink="/" (click)="state.closeMenu()">
            <span [innerHTML]="mainContent.subtitleFirstLine"></span>
            <br>
            <span [innerHTML]="mainContent.subtitleSecondLine"></span>
        </h3>
    </header>

    <div class="mobile-menu" #mobileMenu  [ngClass]="{'expanded': state.menuExpanded, 'scrolled': scrolled, 'overflow': overflow}">
        <app-menu></app-menu>
        <header>
            <ul class="lang" #lang2 (click)="lang2.classList.toggle('expand')">
                <li [class.active]="isActive('en')" (click)="setLang('en')">EN</li>
                <li [class.active]="isActive('es')" (click)="setLang('es')">ES</li>
                <li [class.active]="isActive('pt')" (click)="setLang('pt')">PT</li>
            </ul>
            <h1 class="title" [innerHTML]="mainContent.title" routerLink="/" (click)="state.closeMenu()">
            </h1>
            <h3 class="subtitle" routerLink="/" (click)="state.closeMenu()">
                <span [innerHTML]="mainContent.subtitleFirstLine"></span>
                <br>
                <span [innerHTML]="mainContent.subtitleSecondLine"></span>
            </h3>
        </header>
    </div>

    <div class="sponsors" #sponsors [ngStyle]="{'top': menuBottom ? menuBottom + 'px' : 'unset'}" [ngClass]="{'fixed': overflow, 'expanded': state.menuExpanded}">
        <a href="https://www.leverhulme.ac.uk/" target="_blank" rel="noopener noreferrer"><img class="sponsor-image"
                src="../assets/icons/sponsor1.svg" alt="Leverhulme Trust"></a>

        <a href="http://cvcsrv.arct.cam.ac.uk/" target="_blank" rel="noopener noreferrer">
            <img class="sponsor-image padding" src="../assets/icons/sponsor2.svg"
                alt="CVC The Center for Visual Culture">
        </a>

        <a href="https://www.hoart.cam.ac.uk/" target="_blank" rel="noopener noreferrer">
            <img class="sponsor-image" src="../assets/icons/sponsor4.svg" alt="ual: transnational art identity">
        </a>
        <a href="https://www.arts.ac.uk/" target="_blank" rel="noopener noreferrer">
            <img class="sponsor-image" src="../assets/icons/ual_logo.svg" alt="University of Cambridge">
        </a>
    </div>

    <!-- main -->

    <main>
        <router-outlet></router-outlet>
    </main>

    <!-- main -->
    <footer>
        <h2 id="footer-message" appRevealText>
            <span [innerHTML]="mainContent.registerInfoFirstLine"></span>
        </h2>
        <h2 appRevealText> <span [innerHTML]="mainContent.registerInfoSecondLine"></span></h2>
        <a id="footer-email" target="_blank" href="https://us02web.zoom.us/webinar/register/WN_ucFNxvkOT2yavnsg5yJT_g" appRevealText>{{getFooter()}}</a>
    </footer>
    <ng-container>