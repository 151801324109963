import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getLang } from '../lang.config';


interface ISpeaker {
  id: string;
  name: string;
  about: string;
  video?: string;
}

export interface IEventDetails {
  id: any;
  imagePath: string;
  imageDescription: string;
  date?: string;
  startTime: string;
  endTime: string;
  name: string;
  chair: ISpeaker[];
  keynote?: ISpeaker[];
  speakers: ISpeaker[];
  shortDescription: string;
  longDescription: string;
  noMargin?: boolean;
  video?: string;
  hideDate?: boolean;
}

@Component({
  selector: 'app-article-presentation',
  templateUrl: './article-presentation.component.html',
  styleUrls: ['./article-presentation.component.scss']
})
export class ArticlePresentationComponent implements OnInit {
  @Input() event: IEventDetails | undefined;
  @HostBinding('class.small-margin') noMargin: boolean | undefined = false;

  viewSessions: Map<string, string> = new Map<string, string>([
    ['en', 'View session'],
    ['es', 'Ver sesión'],
    ['pt', 'Ver sessão']
  ]);

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.noMargin = this.event?.noMargin;
  }

  navigateToEventDetail(): void {
    if (!this.event) {
      return;
    }

    this.router.navigate([`${this.event.id}`]);
  }

  getSessionButton(): string {
    const lang = getLang() as string;
    return this.viewSessions.get(lang) as string;
  }
}
