import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IEventDetails } from '../article-presentation/article-presentation.component';

@Injectable()
export class StateService {
  private main: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private eventItems: BehaviorSubject<IEventDetails[] | null> = new BehaviorSubject<IEventDetails[] | null>(null);
  private menu = false;
  private eventPage: boolean = false;
  private eventPageChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.eventPage);
  constructor() {

  }

  get mainContent(): Observable<any> {
    return this.main.pipe(filter((value) => !!value));
  }

  setMainContent(value: any): void {
    this.main.next(value);
  }

  get isEventPage(): boolean {
    return this.eventPage;
  }

  set isEventPage(value: boolean) {
    this.eventPage = value;
    this.eventPageChange.next(this.eventPage);
  }

  onEventPageChanged(): Observable<boolean> {
    return this.eventPageChange.asObservable();
  }

  get events(): Observable<IEventDetails[]> {
    return this.eventItems.pipe(filter(items => !!items)) as any;
  }

  get menuExpanded(): boolean {
    return this.menu;
  }

  closeMenu(): void {
    this.menu = false;
  }

  toggleMenu(): void {
    this.menu = !this.menu;
  }


  setEvents(items: IEventDetails[]): void {
    this.eventItems.next(items);
  }
}
