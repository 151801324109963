<ng-container *ngIf="state.mainContent | async as mainContent">
    <div class="menu" (click)="toggleMenu()">
        <img class="menu-icon" src="../assets/icons/menu-icon.svg" alt="Menu" *ngIf="!state.menuExpanded">
        <img class="menu-icon-mobile" src="../assets/icons/menu-icon_white.svg" alt="Menu" *ngIf="!state.menuExpanded">
        <img src="../assets/icons/menu-close.svg" alt="Menu" *ngIf="state.menuExpanded">
    </div>
    
    <div class="overlay" *ngIf="state.menuExpanded"></div>
    
    <div class="menu-items" *ngIf="state.menuExpanded">
        <!-- <a class="menu-item" routerLink="/about" routerLinkActive="active" (click)="closeMenu()">About</a> -->
        
        <div class="menu-item event" *ngFor="let event of state.events | async" routerLinkActive="active"
            [routerLink]="'/' + event.id" (click)="closeMenu()">
            <div class="name" [innerHTML]="event.name"></div>
            <div class="date-time">
                <div class="date" *ngIf="!!event.date">{{event.date | ordinalDate}}</div>
                <div class="time"><span>{{event.startTime | date: 'h:mm' : 'UTC'}}</span> - <span>{{event.endTime |
                        date: 'h:mm a' : 'UTC'}}</span></div>
            </div>
        </div>
        
    </div>
</ng-container>