import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ViewportStateService {

  private viewport: Subject<void> = new Subject<void>();
  private interval: any | null = null;
  private viewportHeight = 0;

  constructor() { }


  startCheck(): void {
    this.viewportHeight = this.getViewportHeight();
    this.interval = setInterval(() => {
      const newHeight = this.getViewportHeight();
      if (newHeight === this.viewportHeight) {
        return;
      }
      this.viewportHeight = newHeight;
      this.viewport.next();

    }, 1000);
  }

  stopCheck(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  viewportHeightChanged(): Observable<void> {
    return this.viewport.asObservable();
  }

  private getViewportHeight(): number {
    return document.documentElement.scrollHeight;
  }
}
